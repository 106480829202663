.framePage {
  padding: 0;
}
.ovy-a {
  overflow-y: scroll;
}
.ovy-a::-webkit-scrollbar {
  width: 0 !important;
}
.ProofOfClassHours {
  font-size: 16px;
}
.ProofOfClassHours .ProofOfClassHours_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.ProofOfClassHours .ProofOfClassHours_1 .ProofOfClassHours_1_1 {
  width: 80px;
  flex-shrink: 0;
}
.ProofOfClassHours .ProofOfClassHours_1 .ProofOfClassHours_1_2 {
  width: 100%;
}
.ProofOfClassHours .ProofOfClassHours_2 {
  display: flex;
  margin-bottom: 10px;
}
.ProofOfClassHours .ProofOfClassHours_2 .ProofOfClassHours_2_1 {
  width: 50%;
}
.ProofOfClassHours .ProofOfClassHours_2 .ProofOfClassHours_2_2 {
  width: 30%;
}
.ProofOfClassHours .ProofOfClassHours_2 .ProofOfClassHours_2_3 {
  width: 20%;
}
.ProofOfClassHours .ProofOfClassHours_3 {
  margin-bottom: 20px;
  background: #f2f2f2;
  padding: 6px;
  border-radius: 4px;
}
.ProofOfClassHours .ProofOfClassHours_3 .ProofOfClassHours_title {
  font-weight: 600;
}
.ProofOfClassHours .ProofOfClassHours_3 .ProofOfClassHours_3_1 {
  margin-top: 10px;
  display: flex;
}
.ProofOfClassHours .ProofOfClassHours_3 .ProofOfClassHours_3_1 .ProofOfClassHours_3_1_1 {
  width: 50%;
  word-wrap: break-word;
}
.ProofOfClassHours .ProofOfClassHours_3 .ProofOfClassHours_3_1 .ProofOfClassHours_3_1_2 {
  width: 30%;
}
.ProofOfClassHours .ProofOfClassHours_3 .ProofOfClassHours_3_1 .ProofOfClassHours_3_1_3 {
  width: 20%;
}

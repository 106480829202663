




















































































.framePage {
  padding: 0;
}

.ovy-a {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0 !important
  }
}

.ProofOfClassHours {
  font-size: 16px;

  .ProofOfClassHours_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;

    .ProofOfClassHours_1_1 {
      width: 80px;
      flex-shrink: 0;
    }

    .ProofOfClassHours_1_2 {
      width: 100%;
    }
  }

  .ProofOfClassHours_2 {
    display: flex;
    margin-bottom: 10px;

    .ProofOfClassHours_2_1 {
      width: 50%;
    }

    .ProofOfClassHours_2_2 {
      width: 30%;
    }

    .ProofOfClassHours_2_3 {
      width: 20%;
    }
  }

  .ProofOfClassHours_3 {
    margin-bottom: 20px;
    background: #f2f2f2;
    padding: 6px;
    border-radius: 4px;

    .ProofOfClassHours_title {
      font-weight: 600;
    }

    .ProofOfClassHours_3_1 {
      margin-top: 10px;
      display: flex;

      .ProofOfClassHours_3_1_1 {
        width: 50%;
        word-wrap: break-word;
      }

      .ProofOfClassHours_3_1_2 {
        width: 30%;
      }

      .ProofOfClassHours_3_1_3 {
        width: 20%;
      }
    }
  }
}
